<template>
	<div>
		<a-modal :visible="visible" title="Catálogo de Productos y Servicios SAT" :closable="false" width="45%">
			<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
				<div class="row">
					<div class="col-md-12">
						<label>Producto o Servicio</label>
						<a-input-search v-model="searchSATProduct" placeholder="Ingresa texto para buscar en el SAT" enter-button @search="onSearch" />
					</div>
					<div class="col-md-12" v-if="searchSATProductsList.total_results">
						<a-table :columns="columns" :dataSource="searchSATProductsList.data" :row-selection="rowSelection" rowKey="key"></a-table>
					</div>
					<div class="col-md-12" v-if="searchSATProduct && searchSATProductsList.total_results == 0">
						<p class="text-center pt20">- Sin resultados de la búsqueda -</p>
					</div>
				</div>
			</a-spin>
			<template slot="footer">
				<a-button key="back" @click="onCancel"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onSelectedRecord"> Seleccionar </a-button>
			</template>
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'

export default {
	name: 'searchSATProductServiceComponent',
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapGetters('products', ['searchSATProductsList']),
		spinnerLoaderLabel() {
			return this.$store.state.products.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.products.spinnerLoader
		},
	},
	data() {
		return {
			selectedRecord: {},
			searchSATProduct: '',
			columns: [
				{
					title: 'Clave SAT',
					dataIndex: 'key',
					align: 'center',
				},
				{
					title: 'Nombre',
					dataIndex: 'description',
				},
			],
			rowSelection: {
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRecord = {
						description: selectedRows[0].description,
						sat_key: selectedRows[0].key,
					}
				},
				type: 'radio',
			},
		}
	},
	methods: {
		onSearch() {
			if (this.searchSATProduct != '') {
				this.$store.dispatch('products/SAT_SEARCH_PRODUCTS', this.searchSATProduct)
			} else {
				Swal.fire({
					title: 'Productos y Servicios SAT',
					text: 'Debes ingresar texto para la búsqueda',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			}
		},
		onCancel() {
			this.$emit('success')
		},
		onSelectedRecord() {
			if (utilities.objectValidate(this.selectedRecord, 'sat_key', false)) {
				this.$emit('selectedRecord', this.selectedRecord)
			} else {
				Swal.fire({
					title: 'Productos y Servicios SAT',
					text: 'Debes seleccionar un registro para continuar',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			}
		},
	},
	watch: {
		visible(isVisible) {
			if (!isVisible) {
				this.$store.commit('products/SET_STATE', {
					searchSATProductsList: [],
				})
				this.searchSATProduct = ''
			}
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>